<template>
    <div>
        <el-form :model="form" label-width="80px" size="small">
            <el-form-item label="标题">
                <el-input v-model.trim="form.title"></el-input>
            </el-form-item>
            <el-form-item label="分类名">
                <el-select v-model="form.art_cat_id" placeholder="请选择">
                    <el-option v-for="item in opt_fl" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="阅读量">
                <el-input-number v-model="form.look_nums" :min="0"></el-input-number>
            </el-form-item>
            <el-form-item label="简介">
                <el-input v-model.trim="form.intro"></el-input>
            </el-form-item>
            <el-form-item label="关键字">
                <el-input v-model.trim="form.keyword"></el-input>
            </el-form-item>
            <el-form-item label="底部推荐">
                <el-select v-model="form.solution_id" placeholder="请选择相关联的解决方案二级">
                    <el-option v-for="item in opt_jj" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="缩略图">
                <el-upload
                    :headers="{ token: token }"
                    :limit="1"
                    name="thumbnail"
                    :action="imgBaseUrl + '/api/Project/upload'"
                    list-type="picture-card"
                    :multiple="false"
                    :file-list="fileList"
                    :on-success="success"
                    :on-remove="remove"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="置顶">
                <el-switch
                    class="sw"
                    v-model="form.is_top"
                    active-color="#7781f1"
                    active-text="是"
                    inactive-text="否"
                ></el-switch>
            </el-form-item>
            <el-form-item label="热门">
                <el-switch class="sw" v-model="form.hot" active-color="#7781f1" active-text="是" inactive-text="否"></el-switch>
            </el-form-item>
            <el-form-item label="状态">
                <el-switch
                    class="sw"
                    v-model="form.status"
                    active-color="#7781f1"
                    active-text="显示"
                    inactive-text="隐藏"
                ></el-switch>
            </el-form-item>
            <el-form-item label="详情">
                <editor
                    :getTxt="
                        txt => {
                            form.details = txt;
                        }
                    "
                ></editor>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import editor from "@/components/customs/editor.vue";

export default {
    components: { editor },
    data() {
        return {
            form: {
                title: "",
                art_cat_id: "",
                look_nums: 0,
                is_top: false,
                hot: false,
                status: true,
                intro: "",
                keyword: "",
                details: "",
                little_image: "",
                solution_id: "",
            },
            token: "",
            fileList: [],
            opt_fl: [],
            opt_jj: [],
        };
    },
    created() {
        this.token = Cookies.get("token");
        this.getOpt();
    },
    methods: {
        getForm() {
            return { ...this.form };
        },
        async getOpt() {
            // 获取分类名
            let { data } = await axios.get("/api/article/categoryshow");
            this.opt_fl = data.map(e => {
                return {
                    value: e.id,
                    label: e.name,
                };
            });
            let { data: jj } = await axios.get("/api/solution/sshow");
            this.opt_jj = jj.map(e => {
                return {
                    value: e.id,
                    label: e.title,
                };
            });
        },
        success(url) {
            this.form.little_image = axios.defaults.baseURL + url;
        },
        remove() {
            this.form.little_image = "";
        },
    },
};
</script>

<style lang="less" scoped>
.sw {
    /deep/.is-active {
        color: #7781f1 !important;
    }
}
</style>
