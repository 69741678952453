<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>威有动静</el-breadcrumb-item>
                <el-breadcrumb-item>全部文章</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div class="_add">
                <el-select v-model="fl_id" clearable placeholder="选择分类" @change="search">
                    <el-option v-for="el in fl_id_opt" :key="el.id" :label="el.name" :value="el.id"></el-option>
                </el-select>
                <button @click="openAdd">添加</button>
            </div>
            <div>
                <vy-search-box
                    v-model="value"
                    :btn-click="search"
                    placeholder="请输入内容"
                    :select-list="[
                        { label: '标题', value: 'title' },
                        { label: '内容', value: 'details' },
                        { label: '关键字', value: 'keyword' },
                    ]"
                    :select-value="select"
                    :select-clearable="false"
                    @change="val => (select = val)"
                    select
                ></vy-search-box>
            </div>
        </div>
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="标题">
                    <template slot-scope="{ row }">
                        <span>{{ row.title.length <= 20 ? row.title : row.title.slice(0, 20) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="art_cat_name" align="center" label="分类名"> </el-table-column>
                <el-table-column prop="look_nums" align="center" label="阅读量"> </el-table-column>
                <!-- 其实就是简介 -->
                <el-table-column prop="intro" align="center" label="描述">
                    <template slot-scope="{ row }">
                        <span>{{ row.intro.length <= 20 ? row.intro : row.intro.slice(0, 20) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" align="center" label="更新时间"> </el-table-column>
                <el-table-column prop="is_top" align="center" label="置顶">
                    <template slot-scope="{ row }">
                        <span>{{ row.is_top ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="hot" align="center" label="热门">
                    <template slot-scope="{ row }">
                        <span>{{ row.hot ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#d66bff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页器 -->
            <vy-pager
                :count="total"
                :page="page"
                :page-size="pageSize"
                @current-change="currentChange"
                @size-change="sizeChange"
            ></vy-pager>
        </div>
        <!-- 添加弹框 -->
        <el-dialog title="添加文章" :visible.sync="addShow" width="70%" :show-close="false" :before-close="bfc">
            <template v-if="addShow">
                <addFormVue ref="addForm"></addFormVue>
            </template>
            <span slot="footer">
                <el-button @click="addShow = false">取 消</el-button>
                <el-button type="primary" @click="yesAdd">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑弹框 -->
        <el-dialog title="编辑文章" :visible.sync="setShow" width="70%" :show-close="false" :before-close="bfc">
            <template v-if="setShow">
                <setFormVue ref="setForm" :dataForm="row"></setFormVue>
            </template>
            <span slot="footer">
                <el-button @click="setShow = false">取 消</el-button>
                <el-button type="primary" @click="yesSet">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import addFormVue from "./addForm.vue";
import setFormVue from "./setForm.vue";
import qs from "qs";
import VyPager from "@/components/customs/VyPager.vue";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: { addFormVue, setFormVue, VyPager, VySearchBox },
    data() {
        return {
            tableData: [],
            select: "title",
            value: "",
            total: 1,
            page: 1,
            pageSize: 10,
            fl_id: "",
            fl_id_opt: [],
            addShow: false,
            setShow: false,
            row: {},
        };
    },
    created() {
        this.getData();
        this.getFl_id();
    },
    methods: {
        async getData() {
            let { page, value, select, fl_id: art_cat_id, pageSize } = this;
            let obj = { page, art_cat_id, pageSize };
            obj[select] = value;
            let {
                data: { data, count },
            } = await axios.get("/api/article/show", { params: { ...obj } });
            this.total = count;
            this.tableData = data;
        },
        async getFl_id() {
            let { data } = await axios.get("/api/article/categoryshow");
            this.fl_id_opt = data.map(e => {
                return {
                    id: e.id,
                    name: e.name,
                };
            });
        },
        // 打开添加弹框
        openAdd() {
            this.addShow = true;
        },
        // 打开编辑弹框
        openSet(row) {
            this.row = { ...row };
            this.setShow = true;
        },
        // 确认添加
        async yesAdd() {
            let f = this.$refs.addForm.getForm();
            let { data } = await axios.post(
                "/api/article/add",
                qs.stringify({
                    ...f,
                    is_top: Number(f.is_top),
                    hot: Number(f.hot),
                    status: Number(f.status),
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.addShow = false;
                this.getData();
            }
        },
        // 确认编辑
        async yesSet() {
            let f = this.$refs.setForm.getForm();
            let { data } = await axios.put(
                "/api/article/update",
                qs.stringify({
                    ...f,
                    is_top: Number(f.is_top),
                    hot: Number(f.hot),
                    status: Number(f.status),
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
                this.getData();
            }
            this.setShow = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/article/del", { params: { id } });
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.getData();
        },
        search() {
            this.getData();
        },
        currentChange(page) {
            this.page = page;
            this.search();
        },
        sizeChange(pageSize) {
            this.pageSize = pageSize;
            this.search();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
</style>
